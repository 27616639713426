/* Home */
.main-container {
    display: flex;
    flex-direction: row;
}

.logo-container {
    display: flex;
    max-width: 75px;
    margin: calc(5vh + 20px) 20px;
    flex-basis: 100%;
}

.main-text {
    margin: 5vh 5vw;
    display: flex;
}

.main-text > .column {
    flex-grow: 1;
    flex-basis: 0;
    display: flex;
    flex-direction: column;
    align-self: center;
}

.main-text > .first {
    font-size: 36px;
    line-height: 50px;
}

.main-text > .second {
    font-size: 16px;
    line-height: 22.5px;
}

.home-subtext {
    padding-top: 20px;
}

.home-boldsubtext {
    font-weight: bold;
    font-size: 20px;
    line-height: 28px
}

@media (max-width: 768px) {
    .main-container, .main-text {
        flex-direction: column;
    }

    .logo-container {
        margin: 20px;
    }
}

@media (max-width: 480px) {
    .main-text > .second {
        display: none;
    }
}