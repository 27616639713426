.about-container {
    background-color: #FFF3B3;
    font-size: 20px;
    line-height: 28px;
    padding-right: 30vw;
}

@media (max-width: 768px) {
    .about-container {
        padding-right: 10vw;
    }
}
