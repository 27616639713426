/* Events */
.events-container {
    font-size: 16px;
    background-color: #FFC5BA;
    padding: 75px 10vw;
}

.event-scroll  {
    display: flex;
    overflow-x: auto;
}

.event-container {
    margin: 40px;
}

.event-img {
    width: 250px;
    height: 250px;
}