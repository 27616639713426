/* Contact */

.contact-container {
    background-color: #ADC4FF;
    flex-direction: row;
    align-items: center;
}

.subheader {
    font-weight: 700;
    font-size: 25px;
    margin: 20px 0;
}

.contact-input {
    width: 100%;
    height: 30px;
    max-width: 500px;
    font-size: 12px;
    padding: 5px;
    font-family: 'neuzeit-grotesk';
    border: 2px solid black;
    margin: 10px 0;
}

.contact-button {
    width: calc(100% + 13px);
    height: 40px;
    max-width: 514px;
    font-size: 12px;
    border: 2px solid black;
    font-size: 12px;
    font-family: 'neuzeit-grotesk';
    background-color: black;
    color: white;
    margin: 10px 0;
}

.contact-button:hover {
    cursor: pointer;
}