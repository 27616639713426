body {
    font-family: neuzeit-grotesk, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    overflow-x: hidden;
    margin: 0;
}

#root {
    left: 0;
    top: 0;
    position: relative;
    min-height: 100vh;
}

.Page {
    height: auto;
    overflow-y: auto;
    flex: 1 1 0;
}

.logo {
    background-image: url('../assets/logo.png');
    height: 65px;
    width: 75px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}

.about-container, .resources-container, .contact-container {
    display: flex;
    flex-direction: column;
    padding: 50px 10vw;
    flex: 1 1 0;
    min-height: 100%;
    justify-content: center;
}

.resource-text, .about-text {
    margin: 10px 0;
}

.column {
    flex-grow: 1;
    flex-basis: 0;
    padding: 20px;
    padding-right: 50px;
}
  
#main-content {
    padding-bottom: 150px;    /* Footer height */
}

/* Footer */
.bottom-copyright {
    display: flex;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 150px;            /* Footer height */
    justify-content: center;
}

.bottom-container {
    padding: 20px 0;
    font-size: 15px;
    text-align: center;
}