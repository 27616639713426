/* Resources */

.resources-container {
    background-color: #B3F8CB;
    flex-direction: row;
}

.microheader {
    font-weight: 700;
}

@media (max-width: 768px) {
    .resources-container {
        flex-direction: column;
    }
}